p {
    padding: 0.5em 0 !important;
}

p,h2,h3,h4,li {
    color:#2b2b2b;
}

a{
  padding:0;
  margin: 0;
}

p::selection {
    background: #06dce6;
    color: #fff;
  }

  h2::selection {
    background: #06dce6;
    color: #fff;
  }

  h3::selection {
    background: #06dce6;
    color: #fff;
  }

  h4::selection {
    background: #06dce6;
    color: #fff;
  }

  span::selection {
    background: #06dce6;
    color: #fff;
  }

.brah {
    color: transparent;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

button {
  border: none;
  cursor: pointer;
  transition: all ease-out .3s;
  width: 100%;
  max-width: 30em;
  padding: 1.3em 0 ;
}

button :hover {
  transition: all ease-out .3s;
}

/* header */
header {
    position: fixed;
    width: 100%;
    text-align: center;
    margin: auto;
    z-index: 999;
    transition: 0.6s;
    padding: 0.5em 0;
  }

header.sticky {
    background-color: #fff;
  }


  .reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;;
    transition: all 2s ease;
}

.reveal.active{
    transform: translateY(0px);
    opacity: 1;
}


/* ----------------------- */
/* page */
/* ----------------------- */
.display-none {
  display: none;
}


.scrollTop{
  position: fixed;
  bottom: 2.5em;
  text-align: center;
  left: 1em;
  width: 60px;
  height: 60px;
  background-color: #06dce6;
  border-radius: 50%;
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 2.3em;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  color: #fff;
  border: 2px solid #fff;
cursor:pointer;
 z-index: 99;
}

.scroll-to-top{
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #06dce6;
  color: #fff;
}

.scroll-to-top i{
 margin-top: 10px;
 animation: scrollTop 0.5s alternate ease;
}

@keyframes scrollTop {
  from{
    transform: translateY(2px);
  }to{
    transform: translateY(-2px);
  }
}

#top-btn.active{
  opacity: 1;
}


#top-btn{
  opacity: 0;
}


*{font-family:"ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "heisei-maru-gothic-std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro"}.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}body{background-color:#f8f8f8}a{text-decoration:none}li{list-style:none}h2{font-size:1.7em !important;font-weight:bold;padding:1.5em 0 0.5em 0 !important}.common__center{width:100%;margin:0 auto;max-width:1100px;text-align:center}.common__item{padding:3em 0 2em 0}@media screen and (max-width: 650px){.common__item{padding:3em 1em 2em 1em}}header{position:fixed;top:0;z-index:99;text-align:center;width:100%;background-color:#f8f8f8}footer{background-color:#f8f8f8;text-align:center;padding:0.2em}.logo{padding:15px 0}nav{height:4em;width:100%;display:flex;justify-content:space-between;position:fixed;bottom:0;padding:0 20px;background-color:#06dce6;z-index:999}nav a{color:#fff}main{background-image:url(/static/media/img_main.2da159b7.png);background-color:#12d6e1;width:100%;height:0;padding-top:60%;background-repeat:no-repeat;background-position:center center;background-size:cover}@media only screen and (max-width: 768px){.logo{display:none}footer{display:none}main{background-image:url(/static/media/img_main_sp.2490526c.png);padding-top:150.9642857143%}}.character{border-radius:50%;width:20em;height:20em}.character__blue{background-image:linear-gradient(135deg, #2AFADF 10%, #4C83FF 100%)}

/**
 * YUI 3.5.0 - reset.css (http://developer.yahoo.com/yui/3/cssreset/)
 * http://cssreset.com
 * Copyright 2012 Yahoo! Inc. All rights reserved.
 * http://yuilibrary.com/license/
 */
/*
	TODO will need to remove settings on HTML since we can't namespace it.
	TODO with the prefix, should I group by selector or property for weight savings?
*/
html{
	color:#000;
	background:#FFF;
}
/*
	TODO remove settings on BODY since we can't namespace it.
*/
/*
	TODO test putting a class on HEAD.
		- Fails on FF.
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin:0;
	padding:0;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
fieldset,
img {
	border:0;
}
/*
	TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style:normal;
	font-weight:normal;
}

ol,
ul {
	list-style:none;
}

caption,
th {
	text-align:left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size:100%;
	font-weight:normal;
}
q:before,
q:after {
	content:'';
}
abbr,
acronym {
	border:0;
	font-feature-settings:normal;
	font-variant:normal;
}
/* to preserve line-height and selector appearance */
sup {
	vertical-align:text-top;
}
sub {
	vertical-align:text-bottom;
}
input,
textarea,
select {
	font-family:inherit;
	font-size:inherit;
	font-weight:inherit;
}
/*to enable resizing for IE*/
input,
textarea,
select {
	*font-size:100%;
}
/*because legend doesn't inherit in IE */
legend {
	color:#000;
}
/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssreset { display: none; }

