p {
    padding: 0.5em 0 !important;
}

p,h2,h3,h4,li {
    color:#2b2b2b;
}

a{
  padding:0;
  margin: 0;
}

p::selection {
    background: #06dce6;
    color: #fff;
  }

  h2::selection {
    background: #06dce6;
    color: #fff;
  }

  h3::selection {
    background: #06dce6;
    color: #fff;
  }

  h4::selection {
    background: #06dce6;
    color: #fff;
  }

  span::selection {
    background: #06dce6;
    color: #fff;
  }

.brah {
    color: transparent;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

button {
  border: none;
  cursor: pointer;
  transition: all ease-out .3s;
  width: 100%;
  max-width: 30em;
  padding: 1.3em 0 ;
}

button :hover {
  transition: all ease-out .3s;
}

/* header */
header {
    position: fixed;
    width: 100%;
    text-align: center;
    margin: auto;
    z-index: 999;
    transition: 0.6s;
    padding: 0.5em 0;
  }

header.sticky {
    background-color: #fff;
  }


  .reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;;
    transition: all 2s ease;
}

.reveal.active{
    transform: translateY(0px);
    opacity: 1;
}


/* ----------------------- */
/* page */
/* ----------------------- */
.display-none {
  display: none;
}


.scrollTop{
  position: fixed;
  bottom: 2.5em;
  text-align: center;
  left: 1em;
  width: 60px;
  height: 60px;
  background-color: #06dce6;
  border-radius: 50%;
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 2.3em;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  color: #fff;
  border: 2px solid #fff;
cursor:pointer;
 z-index: 99;
}

.scroll-to-top{
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #06dce6;
  color: #fff;
}

.scroll-to-top i{
 margin-top: 10px;
 animation: scrollTop 0.5s alternate ease;
}

@keyframes scrollTop {
  from{
    transform: translateY(2px);
  }to{
    transform: translateY(-2px);
  }
}

#top-btn.active{
  opacity: 1;
}


#top-btn{
  opacity: 0;
}

