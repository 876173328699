* {
  font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "heisei-maru-gothic-std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro";
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    
  }
}

//common
body {
  background-color: rgb(248, 248, 248);
  
}

a{
  text-decoration: none;
}

li {
  list-style: none;
}

h2 {
  font-size: 1.7em !important;
  font-weight: bold;
  padding: 1.5em 0 0.5em 0 !important;
}

// .contener {
//   max-width: 25em;
// padding: 0 2em;
// }


.common__center {
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  text-align: center;
}

.common__item {
  padding: 3em 0 2em 0;
}
@media screen and (max-width: 650px) {
.common__item {
  padding: 3em 1em 2em 1em;
}
}

// Header
header {
  position: fixed;
  top: 0;
  z-index: 99;
  text-align: center;
  width: 100%;
  background-color: rgb(248, 248, 248);
}

//Footer
footer {
  background-color: rgb(248, 248, 248);
  text-align: center;
  padding: 0.2em;
}



// Nav
.logo {
  padding: 15px 0;
}
nav{
  height: 4em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  padding: 0 20px;
  background-color: #06dce6;
  z-index: 999;
  
  // & ul{
  //   display: flex;
  //   flex-flow: row nowrap;
  // }
  // li {
  //   padding: 18px 10px;
  // }
  a{
    color: #fff;
  }
}

// Hamburger
// .hamburger {
//   width: 2rem;
//   height: 2rem;
//   position: fixed;
//   top: 15px;
//   right: 20px;
//   display: flex;
//   justify-content: space-around;
//   flex-flow: column nowrap;
//   div {
//     width: 2rem;
//     height: 0.25rem;
//     background-color: ${({ open }) => open ? '#ccc' : '#333'};
//     border-radius: 10px;
//   }
// }

// Home
main{
  background-image: url(./asset/img/img_main.png);
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // // background-size: cover;
  // height: 100vh;
  // background-size: 100%;
  // // height: 1200px;
  background-color: #12d6e1;
  // margin-top:4em;
  width: 100%;
  height: 0;

  /* (画像の高さ / 画像の横幅) × 100 */
  padding-top: 60%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
}



// Responsive PC
@media only screen and (max-width: 768px) {
  //nav
  .logo {
    display: none;
  }
  //Footer
  footer {
    display: none;
  }
  // Home Main
  main {
    background-image: url(./asset/img/img_main_sp.png);
    // padding-top: 88%;
    padding-top: 150.9642857143%;
    // background-size: 100%;
    // height: 100vh;
    // background-position: center;

  }
}

// character

.character{
  
  border-radius: 50%;
  width: 20em;
  height: 20em;
  &__blue{
    background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
  }
}